import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { CSVReader } from "react-papaparse";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import CampaignsSelect from "../CampaignsSelect";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import SystemUpdateAltSharpIcon from "@material-ui/icons/SystemUpdateAltSharp";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  uploadCsv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  downloadCsv: {
    cursor: "pointer",
    marginBottom: 16,
  },
  csvHandler: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 10,
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },

  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const initialState = {};

  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const buttonRef = React.createRef();

  const [isUpload, setIsUpload] = useState(false);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`campaigns/${whatsAppId}`);
        setWhatsApp(data);
        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleSaveWhatsApp = async (values) => {
    const whatsappData = {
      ...values,
      whatsappId: selectedQueueIds,
      leds: csvData
    };

    try {
      if (whatsAppId) {
        await api.put(`/campaigns/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/campaigns", whatsappData);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
    setIsUpload(false);
    setCsvData([]);
  };

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

 

  const handleOnFileLoad = (data) => {
    setIsUpload(true);

    const dataValues = data.map((data) => {
      const csv = data.data;
      //Sigla ;Data;Hora;Nome;Descrição;Telefone;Placa;Responsável;Concluída?;OS/Orçamento
      const { Data, Nome, Descrição, Telefone, Placa } = csv;
      const date = Data.split(' ')[0];
      return {
        data: date.substr(0, 10).split("/").reverse().join("-"),
        placa: Placa,
        nome: Nome,
        tipo: Descrição,
        // veiculo: Veículo,
        telefone: Telefone.replace(/\D/g, ""),
      };
    });

    setCsvData(dataValues);

    // console.log(dataValues)
    console.log("------------UPLOAD---------------");
    console.log(data);
    console.log("---------------------------");
  };

 
  const handleOnError = (err, file, inputElem, reason) => {
    console.log("---------------------------");
    console.log(err);
    console.log("---------------------------");
  };

  const handleOnRemoveFile = (data) => {
    setIsUpload(false);
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {whatsAppId ? i18n.t("whatsappModal.title.edit") : "Nova campanha"}
        </DialogTitle>
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          // validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <CampaignsSelect
                  selectedQueueIds={selectedQueueIds}
                  onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                />

                <div>
                  <CSVReader
                    ref={buttonRef}
                    onFileLoad={handleOnFileLoad}
                    onError={handleOnError}
                    noClick
                    noDrag
                    onRemoveFile={handleOnRemoveFile}
                    config={{
                      header: !0,
                      skipEmptyLines: "greedy",
                      delimiter: ";",
                    }}
                  >
                    {({ file }) => (
                      <div className={classes.uploadCsv}>
                        {!isUpload ? (
                          <>
                            <SystemUpdateAltSharpIcon color="primary" />
                            <span onClick={handleOpenDialog}>
                              Importar arquivo CSV
                            </span>
                          </>
                        ) : (
                          <>
                            <CloseSharpIcon
                              color="secondary"
                              onClick={handleRemoveFile}
                            />
                            {file && file.name}
                          </>
                        )}
                      </div>
                    )}
                  </CSVReader>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("whatsappModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {whatsAppId
                    ? i18n.t("whatsappModal.buttons.okEdit")
                    : i18n.t("whatsappModal.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
