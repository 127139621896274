const messages = {
  en: {
    translations: {
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Please login!",
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Enter",
          register: "Don't have an account? Register!",
        },
      },
      auth: {
        toasts: {
          success: "Login successfully!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets today: ",
          },
        },
		messages: {
          inAttendance: {
            title: "In Service"
          },
          waiting: {
            title: "Waiting"
          },
          closed: {
            title: "Finished"
          }
        },
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection deleted sucessfully!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? It cannot be reverted.",
          disconnectTitle: "Disconnect",
          disconnectMessage: "Are you sure? You'll need to read QR Code again.",
        },
        buttons: {
          add: "Add WhatsApp",
          disconnect: "Disconnect",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connectiing",
        },
        toolTips: {
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },
        },
        table: {
          name: "Name",
          status: "Status",
          lastUpdate: "Last Update",
          default: "Default",
          actions: "Actions",
          session: "Session",
        },
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          name: "Name",
          default: "Default",
          farewellMessage: "Farewell Message",      
          transferTicketMessage: "Ticket Transfer Message",
          reopenLastTicket: "Reopen Last Ticket",
          minutes: "Minutes",
          newticketafter: "Generate a New Ticket after",
          titlenewticketafter: "When receiving a message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      qrCode: {
        message: "Read QrCode to start the session",
      },
      tagsPage: {
        title: "Tags",
        searchPlaceholder: "Search Tag",
        table: {
          name: "Name",
          content: "Content",
          actions: "Actions",
          color: "Color",
          contacts: "Number of Contacts"
        },
        buttons: {
          add: "Add",
        },
        toasts: {
          deleted: "Deleted Tag",
        },
        confirmationModal: {
          deleteTitle: "delete Tag",
          deleteMessage: "Are you sure you want to delete this tag?",
        },
      },
      tagsModal: {
        title: {
          edit: "Edit Tag",
          add: "Add Tag",
        },
        form: {
          mainInfo: "Tag Data",
          name: "Name",
          color: "Color"
        },
        buttons: {
        okAdd: "Add",
        okEdit: "Salve",
        cancel: "Cancel",
        },
        success: "Tag Saved Successfully.",
      },
      programed_messages: {
        title: "Msg. Scheduled",
        toasts: {
          deleted: "Scheduled message deleted successfully!",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Del ",
          deleteMessage:
            "Are you sure you want to delete this scheduled message?",
        },
        buttons: {
          add: "Add a Scheduled Message ",
        },
        table: {
          date: "Scheduled for",
          content: "Content",
          sent: "Sent in",
          actions: "Actions",
          to: "To",
        },
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted sucessfully!",
        },
        searchPlaceholder: "Search ...",
        confirmationModal: {
          deleteTitle: "Delete",
          importTitlte: "Import contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related tickets will be lost.",
          importMessage: "Do you want to import all contacts from the phone?",
        },
        buttons: {
          import: "Import Contacts",
          add: "Add Contact",
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Actions",
        },
      },
      msgPage: {
        title: "Msg. Scheduler",
        toasts: {
          deleted: "Message successfully deleted!",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Delete",

          deleteMessage: "Are you sure you want to delete this message?",
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          msg: "Msg",
          date: "Send Date",
          actions: "Actions",
          status: "Status",
        },
        status: {
          sent: "Sent",
          pending: "Pending",
          error: "Error (Not Sent)"
        }
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "Whatsapp number",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",          
          activecampaign: "Enable Campaign Send"
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Quick Reply saved successfully.",
      },
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        form: {
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting Message",
          transferQueueMessage: "Inter-Sector Transfer Message",
          outOfWorkMessage: "Customize the message when outside office hours",
          startWorkHour: "Office: Opening",
          endWorkHour: "Office: Closing",
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          sunday: "Sunday",
          holiday: "Holiday",
          token: "Token",
          checkHourExpedient: "Check Office Hours",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
	  subQueueModal: {
        title: {
          add: "Add Submenu",
          edit: "Edit Submenu",
        },
        form: {
          name: "Name",
          greetingMessage: "Message",
		  typemenu: "Type of Menu",
		  typemenuselect: {
			  selecione: "Select a Type",
			  consapi: "Query API",
			  infor: "Informative",
			  endticket: "End Ticket",
			  linkatendente: "Link to Attendant",
			  linkfila: "Link to Queue",
			  returnmenu: "Return to Menu"
		  },
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolved" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search tickets and messages.",
        },
        buttons: {
          showAll: "All",
        },
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search for users",
		    fieldQueueLabel: "Transfer to queue",
        fieldQueuePlaceholder: "Please select a queue",        
        transferTicketMsgLabel: "Send Transfer Message",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      programMsgModal: {
        title: "Schedule Scheduled Message",
        dateLabel: "Send date",
		    msgLabel: "Insert Message Here",
        buttons: {
          send: "Send",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Queue",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No tickets found with this status or search term.",
        buttons: {
          accept: "Accept",
        },
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search for a contact",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Connections",
          tickets: "Tickets",
          contacts: "Contacts",
          quickAnswers: "Quick Answers",
          queues: "Queues",
          administration: "Administration",
          users: "Users",
          settings: "Settings",	  
		      chatbot: "ChatBot",
          messagesApi: "Messages API",
          whatsappApi: "Token API",
          msg: "Scheduled Message",
          envio: "Campaigns",
        },
        appBar: {
          user: {
            profile: "Profile",
            logout: "Logout",
          },
        },
      },
      messagesApi: {
        title: "API",
        text: {
          number: "Number",
          body: "Message",
          token: "Token registered",
        },
        media: {
          number: "Number",
          body: "File name",
          media: "File",
          token: "Token registered",
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      queues: {
        title: "Queues",
        table: {
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
        },
      },
      chatBot: {
        title: "Chatbot - Automated Attendant",
        table: {
          name: "Name",
          color: "Color",
          greeting: "Greeting Message",
          actions: "Actions",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? This action cannot be reversed! The services in this queue will continue to exist, but they will no longer have any sectors assigned.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
	  userSelect: {
        inputLabel: "Attendants",
      },
      quickAnswers: {
        title: "Quick Answers",
        table: {
          shortcut: "Shortcut",
          message: "Quick Reply",
          actions: "Actions",
        },
        buttons: {
          add: "Add Quick Reply",
        },
        toasts: {
          deleted: "Quick Reply deleted successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick Reply: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      users: {
        title: "Users",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          actions: "Actions",
          status: {
            activated: "Activated",
            disabled: "Disabled"
          }
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted sucessfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open tickets will be moved to queue.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          userCreation: {
            name: "User creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          evaluation:{
            name: "Avaliação do atendimento",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
        uploadLogo:{
            name: "Send logo (200x100)",
            buttonName: "Open File",
            buttonName2: "Send",
          },
		  CheckMsgIsGroup: {
			name: "Ignore Msg from Groups",
			options: {
        enabled: "Enabled",
        disabled: "Disabled",
			},
		  },
		  SelectListTickets: {
			name: "Ticket Display Mode",			
		  },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            resolve: "Resolve",
            reopen: "Reopen",
            accept: "Accept",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Type a message",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        signMessage: "Sign",
      },
      contactDrawer: {
        header: "Contact details",
        buttons: {
          edit: "Edit contact",
        },
        extraInfo: "Other information",
      },
      ticketOptionsMenu: {
        delete: "Delete",
        transfer: "Transfer",
	resolveWithNoFarewell: "End without Farewell",
        confirmationModal: {
          title: "Delete ticket #",
          titleFrom: "from contact ",
          message: "Attention! All ticket's related messages will be lost.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
        programMsg: "Schedule new Message",
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        delete: "Delete",
        reply: "Reply",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
      },
      campaign:{
        title: "Sending Campaigns",
        sendTo: "Send To",
        message: "Message",
        buttons: {
          importToContacts: "Import from Contacts",
          importToCsv: "Import CSV",
          send: "Send",
        },
        tableColumns: {
          contact: "Contact",
          phone: "Number",
          status: "Status",
          actions: "Actions",
        },
        infoMessages:{
          sendOk: "Messages Sent",
          sendError: "Error sending Message",
          msgTo: "Message To",
          sendErrorTo: "Error sending message to",
          alreadySend: "Already sent",
          sending: "Sending Message, Please do not leave the Page",
          csvFormatInvalid: "The CSV file does not have the correct format\n",
          bigFile: "Large File",
          sent: "Sent",
          pending: "Pending",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "There must be at lest one default WhatsApp connection.",
        ERR_NO_DEF_WAPP_FOUND:
          "No default WhatsApp found. Check connections page.",
        ERR_WAPP_NOT_INITIALIZED:
          "This WhatsApp session is not initialized. Check connections page.",
        ERR_WAPP_CHECK_CONTACT:
          "Could not check WhatsApp contact. Check connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Could not download media from WhatsApp. Check connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG:
          "Error sending WhatsApp message. Check connections page.",
        ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "There's already an open ticket for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please login.",
        ERR_USER_CREATION_DISABLED:
          "User creation was disabled by administrator.",
        ERR_NO_PERMISSION: "You don't have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error while creating message on database.",
        ERR_CREATING_TICKET: "Error while creating ticket on database.",
        ERR_FETCH_WAPP_MSG:
          "Error fetching the message in WhtasApp, maybe it is too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "This color is already in use, pick another one.",
        ERR_WAPP_GREETING_REQUIRED:
          "Greeting message is required if there is more than one queue.",
      },
    },
  },
};

export { messages };
