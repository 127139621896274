import React, { useState, useEffect } from "react";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

import { Button, Paper, CircularProgress, IconButton } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  newMessageBox: {
    background: "#eee",
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },

  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: "#fff",
    display: "flex",
    borderRadius: 20,
    flex: 1,
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },

  sendMessageIcons: {
    color: "grey",
  },

  uploadInput: {
    display: "none",
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
}));

const MessageInputMassive = ({ sendMessage, messageBtn }) => {
  const classes = useStyles();

  const [medias, setMedias] = useState([]);
  const [, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading] = useState(false);
  const [messageButton, setMessageButton] = useState([]);

  useEffect(() => {
    setMessageButton(messageBtn ? messageBtn : i18n.t("campaign.buttons.send"));
  }, [messageBtn, medias]);

  useEffect(() => {
    return () => {
      setInputMessage("");
      setShowEmoji(false);
    };
  }, []);

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias2 = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const sendMediaPrim = () => {
    sendMessage(medias);
  };
  if (medias.length > 0)
    return (
      <>
        <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
          <IconButton
            aria-label="cancel-upload"
            component="span"
            onClick={(e) => setMedias([])}
          >
            <CancelIcon className={classes.sendMessageIcons} />
          </IconButton>

          {loading ? (
            <div>
              <CircularProgress className={classes.circleLoading} />
            </div>
          ) : (
            <span>
              {medias[0]?.name}
              {/* <img src={media.preview} alt=""></img> */}
            </span>
          )}
          <IconButton
            aria-label="send-upload"
            component="span"
            // onClick={handleUploadMedia}
            // disabled={loading}
          >
            {/* <SendIcon className={classes.sendMessageIcons} /> */}
          </IconButton>
        </Paper>
        <div style={{ textAlign: "center" }}>
          <Button
            color="primary"
            variant="contained"
            component="span"
            onClick={sendMediaPrim}
          >
            {messageButton}
          </Button>
        </div>
      </>
    );
  else {
    return (
      <>
        <Paper square elevation={0} className={classes.mainWrapper}>
          <div className={classes.newMessageBox}>
            {/*<IconButton
						aria-label="emojiPicker"
						component="span"
						
						onClick={e => setShowEmoji(prevState => !prevState)}
					>
						<MoodIcon className={classes.sendMessageIcons} />
					</IconButton>*/}
            {showEmoji ? (
              <div className={classes.emojiBox}>
                <Picker
                  perLine={16}
                  showPreview={false}
                  showSkinTones={false}
                  onSelect={handleAddEmoji}
                />
              </div>
            ) : null}

            <input
              multiple
              type="file"
              id="upload-button2"
              className={classes.uploadInput}
              onChange={handleChangeMedias2}
            />
            <label htmlFor="upload-button2">
              <IconButton aria-label="upload" component="span">
                <AttachFileIcon className={classes.sendMessageIcons} />
              </IconButton>
            </label>
          </div>
        </Paper>

        <div style={{ textAlign: "center" }}>
          <Button variant="contained" color="inherit" onClick={sendMessage}>
            {messageButton}
          </Button>
        </div>
      </>
    );
  }
};

export default MessageInputMassive;
