import React, { useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import toastError from "../../errors/toastError";
import api from "../../services/api";

const WhatsAppSelect = ({ nameSelect, width, onChange }) => {
  const [conexoes, setConexoes] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/whatsapp/");
        setConexoes(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  return (
    <Select
      name={nameSelect}
      labelId="user-selection-label"
      id="user-selection"
      size="small"
      style={width}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
    >
      {conexoes.map((conexao) => (
        <MenuItem value={conexao.id}>
          {conexao.name} - {conexao.number}
        </MenuItem>
      ))}
    </Select>
  );
};

export default WhatsAppSelect;
