import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "socket.io-client";
import { toast } from "react-toastify";
// import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
// import Avatar from "@material-ui/core/Avatar";
// import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ProgramMsgModal from "../../components/ProgramMsgModal";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
// import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
// import { Can } from "../../components/Can";
import ModalImageCors from "../../components/ModalImageCors";

import AgendaCsv from "../../components/Agenda";
import ButtonSelect from "./Button";
import * as moment from "moment";

const reducer = (state, action) => {
  if (action.type === "LOAD_PROGRAMEDMESSAGES") {
    const programedMessages = action.payload;
    const newProgramedMessages = [];

    programedMessages.forEach((programedmessage) => {
      const programedMessageIndex = state.findIndex(
        (m) => m.id === programedmessage.id
      );
      if (programedMessageIndex !== -1) {
        state[programedMessageIndex] = programedmessage;
      } else {
        newProgramedMessages.push(programedmessage);
      }
    });

    return [...state, ...newProgramedMessages];
  }

  if (action.type === "UPDATE_PROGRAMEDMESSAGES") {
    const programedMessage = action.payload;
    const programedMessageIndex = state.findIndex(
      (m) => m.id === programedMessage.id
    );
    if (programedMessageIndex !== -1) {
      state[programedMessageIndex] = programedMessage;
      return [...state];
    } else {
      return [programedMessage, ...state];
    }
  }

  if (action.type === "DELETE_PROGRAMEDMESSAGES") {
    const programedMessageId = action.payload;

    const programedMessageIndex = state.findIndex(
      (m) => m.id === programedMessageId
    );
    if (programedMessageIndex !== -1) {
      state.splice(programedMessageIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  red: {
    color: "red",
  },
  green: {
    color: "green",
  },
  blue: {
    color: "blue",
  },
}));

const ProgramedMessages = () => {
  const classes = useStyles();
  // const history = useHistory();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [programedMessages, dispatch] = useReducer(reducer, []);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  /*const [contactModalOpen, setContactModalOpen] = useState(false);*/
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [, setSelectedContactId] = useState(null);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [programedModalOpen, setProgramedModalOpen] = useState(false);
  const [deletingMsgProgramed, setDeletingMsgProgramed] = useState(null);
  const [urlBackend, setUrlBackend] = useState(
    process.env.REACT_APP_BACKEND_URL
  );

  //const isMounted = useRef(true);
  // const { user } = useContext(AuthContext);

  /* useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []); */

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchProgramedMsg = async () => {
        try {
          const { data } = await api.get("/programedMsg/", {
            params: { searchParam, pageNumber },
          });
          dispatch({
            type: "LOAD_PROGRAMEDMESSAGES",
            payload: data.programedmessages,
          });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchProgramedMsg();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    if (process.env.REACT_APP_BACKEND_URL.slice(-1) !== "/") {
      setUrlBackend(process.env.REACT_APP_BACKEND_URL + "/");
    } else {
      setUrlBackend(process.env.REACT_APP_BACKEND_URL);
    }

    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
    socket.on("programedmessage", (data) => {
      console.log(data);
      if (data.action === "update" || data.action === "create") {
        dispatch({
          type: "UPDATE_PROGRAMEDMESSAGES",
          payload: data.programedmessages,
        });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_PROGRAMEDMESSAGES",
          payload: +data.programedMessageId,
        });
      }


      if (data.action === "deleteAll") {
        dispatch({
          type: "DELETE_PROGRAMEDMESSAGES",
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenProgramedModal = () => {
    setSelectedMessageId(null);
    setProgramedModalOpen(true);
  };

  const handleOpenProgramedModalCsv = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseProgramModal = () => {
    setSelectedMessageId(null);
    setProgramedModalOpen(false);
  };

  const handleEditProgramedModal = (MessageId) => {
    setSelectedMessageId(MessageId);
    setProgramedModalOpen(true);
  };

  const handleDeleteMsg = async (programedMessageId) => {
    try {
      await api.delete(`/programedMsg/${programedMessageId}`);
      toast.success(i18n.t("Mensagem Excluída"));
    } catch (err) {
      toastError(err);
    }
    setDeletingMsgProgramed(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleDeleteErros = async () => {
    try {
      await api.delete(`/deleteErros`);
      toast.success(i18n.t("Todas Mensagem com Status Erros Excluída"));
    } catch (err) {
      toastError(err);
    }
    setDeletingMsgProgramed(null);
    setSearchParam("");
    setPageNumber(1);
  }

  const handleDeleteAll = async () => {
    try {
      await api.delete(`/deleteAll`);
      toast.success(i18n.t("Todas as Mensagem Excluída"));
    } catch (err) {
      toastError(err);
    }
    setDeletingMsgProgramed(null);
    setSearchParam("");
    setPageNumber(1);
  }

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ProgramMsgModal
        user={user}
        open={programedModalOpen}
        onClose={handleCloseProgramModal}
        messageId={selectedMessageId}
      />
      <AgendaCsv
        open={contactModalOpen}
        onClose={handleCloseProgramModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedMessageId}
      ></AgendaCsv>
      <ConfirmationModal
        title={
          deletingMsgProgramed
            ? `${i18n.t(
                "msgPage.confirmationModal.deleteTitle"
              )} Mensagem Para: ${deletingMsgProgramed.phoneNumber}?`
            : null
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingMsgProgramed ? handleDeleteMsg(deletingMsgProgramed.id) : null
        }
      >
        {deletingMsgProgramed
          ? `${i18n.t("msgPage.confirmationModal.deleteMessage")}`
          : null}
      </ConfirmationModal>

      <MainHeader>
        <Title>{i18n.t("msgPage.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("msgPage.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            color="warning"
            onClick={handleDeleteErros}
          >
            Excluir com erros
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteAll}
          >
            Excluir Todas
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenProgramedModal}
          >
            {i18n.t("programed_messages.buttons.add")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenProgramedModalCsv}
          >
            Carregar Csv
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("msgPage.table.whatsapp")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("msgPage.table.msg")}
              </TableCell>
              <TableCell align="center">{i18n.t("Imagem")}</TableCell>
              <TableCell align="center">
                {i18n.t("msgPage.table.status")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("msgPage.table.date")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("msgPage.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {programedMessages.map((programedmessage) => (
                <TableRow key={programedmessage.id}>
                  <TableCell align="center">
                    {programedmessage.phoneNumber}
                  </TableCell>
                  <TableCell align="center">
                    {programedmessage.message.slice(0, 50)}
                    {programedmessage.message.length >= 50 ? "..." : ""}
                  </TableCell>
                  <TableCell align="center">
                    {programedmessage.imagePath ? (
                      <ModalImageCors
                        imageUrl={
                          urlBackend +
                          "public/" +
                          programedmessage.imagePath?.split("\\")[
                            programedmessage.imagePath?.split("\\").length - 1
                          ]
                        }
                      />
                    ) : (
                      "Sem imagem"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {(() => {
                      if (programedmessage.status === "sent") {
                        return (
                          <text className={classes.green}>
                            {i18n.t("msgPage.status.sent")}
                          </text>
                        );
                      } else if (programedmessage.status === "error") {
                        return (
                          <text className={classes.red}>
                            {i18n.t("msgPage.status.error")}
                          </text>
                        );
                      } else if (programedmessage.status === "pending") {
                        return (
                          <text className={classes.blue}>
                            {i18n.t("msgPage.status.pending")}
                          </text>
                        );
                      }
                    })()}
                  </TableCell>
                  <TableCell align="center">
                    {moment(programedmessage.sendAt).format(
                      "DD/MM/YYYY à[s] HH:mm"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {programedmessage.status === "pending" ||
                    programedmessage.status === "error" ? (
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleEditProgramedModal(programedmessage.id)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    {/*<IconButton
                      size="small"
                      onClick={() => handleSaveTicket(contact.id)}
                    >
                      <WhatsAppIcon />
                    </IconButton> */}
                    {programedmessage.status !== "send" ? (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setConfirmOpen(true);
                          setDeletingMsgProgramed(programedmessage);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default ProgramedMessages;
