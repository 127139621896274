import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { toast } from "react-toastify";
import MessageInputMassive from "../../components/MessageInputMassive";
import { ptBR } from "date-fns/locale";
import { makeStyles } from "@material-ui/core/styles";
import WhatsAppSelect from "../WhatsAppSelect";

const useStyles = makeStyles((theme) => ({
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}));

const ProgramMsgModal = ({ user, open, onClose, contactNumber, messageId }) => {
  const classes = useStyles();
  const [, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [messageProgram, setMessageProgram] = useState("");
  const [numberProgram, setNumberProgram] = useState("");
  const [whatsappProgram, setWhatsappProgram] = useState("");

  useEffect(() => {
    if (contactNumber) {
      setNumberProgram(contactNumber);
    }
  }, [contactNumber]);

  useEffect(() => {
    const fetchTag = async () => {
      if (!messageId) return;
      console.log(messageId);
      try {
        const { data } = await api.get(`/programedMsg/${messageId}`);
        console.log(data);
        setNumberProgram(data.phoneNumber);
        setMessageProgram(data.message);
        setWhatsappProgram(data.wpid);
        console.log(data.sendAt);
        //setStartDate(data.sendAt);
      } catch (err) {
        toastError(err);
      }
    };

    fetchTag();
  }, [messageId, open]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam },
          });
          setOptions(data.users);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, open]);

  const handleClose = () => {
    onClose();
    setMessageProgram(null);
    setNumberProgram(null);
  };
  const sendMessage = (medias) => {
    if (numberProgram === "") {
      alert("Preencha o Número");
      return;
    }
    if (whatsappProgram === "") {
      alert("Selecione a Conexão");
      return;
    }
    if (messageProgram === "") {
      alert("Digite uma Mensagem");
      return;
    }
    handleProgramMsg(medias);
  };
  const handleProgramMsg = async (medias) => {
    let sendAt = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    let msgData;

    if (medias.length > 0) {
      msgData = new FormData();
      msgData.append("id", 0);
      msgData.append("phoneNumber", numberProgram);
      msgData.append("message", messageProgram);
      msgData.append("wpid", whatsappProgram);
      msgData.append("sendby", user.id);
      msgData.append("status", "pending");
      msgData.append("idchat", "0");
      msgData.append("sendAt", sendAt);

      medias.forEach((media) => {
        msgData.append("medias", media);
      });
    } else {
      msgData = {
        id: 0,
        phoneNumber: numberProgram,
        message: messageProgram,
        wpid: whatsappProgram,
        sendby: user.id,
        status: "pending",
        idchat: "0",
        sendAt: sendAt,
      };
    }
    //console.log(sendAt);
    setLoading(true);
    try {
      await api.patch("/programedMsg", msgData);
      handleClose();
      toast.success(i18n.t("Se programó correctamente el mensaje"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
      <DialogTitle id="form-dialog-title">
        {i18n.t("programMsgModal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.multFieldLine}>
          <TextField
            label={i18n.t("Número 551199999999")}
            name="number"
            variant="outlined"
            margin="dense"
            onChange={(e) => setNumberProgram(e.target.value)}
            value={numberProgram}
            style={{ width: 200 }}
            fullWidth
          />
          <FormControl
            variant="outlined"
            margin="dense"
            className={classes.maxWidth}
          >
            <InputLabel id="user-selection-input-label">
              {i18n.t("Conexão")}
            </InputLabel>
            <WhatsAppSelect
              width={{ width: "250px" }}
              nameSelect="whatsappSelect"
              onChange={(e) => setWhatsappProgram(e)}
            />
          </FormControl>
        </div>
        <br></br>
        <text>{i18n.t("programMsgModal.dateLabel")}:</text>
        <div className={classes.multFieldLine}>
          <DatePicker
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="dd/MM/Y HH:mm"
            locale={ptBR}
            timeIntervals={1}
            minDate={new Date()}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={
              <TextField style={{ width: 460 }} variant="outlined" />
            }
          />
        </div>
        <text>
          <br></br>
          {i18n.t("programMsgModal.msgLabel")}:
        </text>
        <div className={classes.multFieldLine}>
          <TextField
            multiline
            rows={5}
            fullWidth
            variant="outlined"
            margin="dense"
            onChange={(e) => setMessageProgram(e.target.value)}
            value={messageProgram}
          />
        </div>
      </DialogContent>
      <MessageInputMassive sendMessage={sendMessage} messageBtn={"Enviar"} />
      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
          disabled={loading}
          variant="outlined"
        >
          {i18n.t("programMsgModal.buttons.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProgramMsgModal;
